.not-found1-container {
  width: 100%;
  padding: 5rem;
  text-align: center;
  display: flex;
  overflow: auto;
  min-height: 71vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & p {
  font-size: 40px;
  font-weight: 600;
  }
}
